<template>
    <div class="nk-app-root">
        <!-- main @s -->
        <div class="nk-main ">
            <!-- wrap @s -->
            <div class="nk-wrap nk-wrap-nosidebar" style="padding: 0px ;">
                <!-- content @s -->
                <div class="nk-content ">
                    <div class="nk-split nk-split-page nk-split-md">
                        <div class="nk-split-content nk-block-area nk-block-area-column nk-auth-container bg-white">
                            <div class="absolute-top-right d-lg-none p-3 p-sm-5">
                                <a href="#" class="toggle btn-white btn btn-icon btn-light" data-target="athPromo"><em class="icon ni ni-info"></em></a>
                            </div>
                            <div class="nk-block nk-block-middle nk-auth-body">
                                    
                                <div class="nk-block-head">
                                    <div class="nk-block-head-content">
                                        <img class="logo-dark logo-img mb-3" src="@/assets/images/logos/logo-lightbg1.svg" alt="logo-dark">
                                        <!-- <h5 class="nk-block-title">Sign-In</h5> -->
                                        <div class="nk-block-des">
                                            <p>Access SoftSuite using your email and password.</p>
                                        </div>
                                    </div>
                                </div><!-- .nk-block-head -->
                                <div>
                                    <div class="form-group">
                                        <div class="form-label-group">
                                            <label class="form-label" for="default-01">Email</label>
                                        </div>
                                        <input v-model="userName" type="text" v-on:keyup.enter="login()" class="form-control form-control-lg" id="default-01" placeholder="Enter your email address or username">
                                    </div><!-- .foem-group -->
                                    <div class="form-group">
                                        <div class="form-label-group">
                                            <label class="form-label" for="password">Password</label>
                                            <router-link class="link link-primary link-sm" :to="{name: 'passwordreset'}">Forgot Password?</router-link>
                                        </div>
                                        <div class="form-control-wrap">
                                            <a tabindex="-1" href="#" class="form-icon form-icon-right passcode-switch" data-target="password">
                                                <em class="passcode-icon icon-show icon ni ni-eye"></em>
                                                <em class="passcode-icon icon-hide icon ni ni-eye-off"></em>
                                            </a>
                                            <input v-model="password" v-on:keyup.enter="login()" type="password" class="form-control form-control-lg" id="password" placeholder="Enter your passcode">
                                        </div>
                                    </div><!-- .foem-group -->
                                    <div class="form-group">
                                        <button v-if="connecting" class="btn btn-lg btn-primary  btn-block" type="button" disabled="">
                                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                <span>Loading...</span>
                                        </button>
                                        <button v-if="!connecting" v-on:click="login()" class="btn btn-lg btn-primary btn-block">Sign in</button>
                                    </div>
                                </div><!-- form -->
                            </div><!-- .nk-block -->
                            
                            <div class="nk-block nk-auth-footer">
                                <div class="nk-block-between">
                                    <ul class="nav nav-sm">
                                        <li class="nav-item">
                                            <router-link class="nav-link" :to="{name: 'termsandconditions'}">Terms &amp; Condition</router-link>
                                        </li>
                                        <li class="nav-item">
                                            <router-link class="nav-link" :to="{name: 'privacypolicy'}">Privacy Policy</router-link>
                                        </li>
                                        <li class="nav-item">
                                            <router-link class="nav-link" :to="{name: 'help'}">Help</router-link>
                                        </li>
                                    </ul><!-- .nav -->
                                </div>
                                <div class="mt-3 text-center">
                                    <a href="https://www.ravenware.co.uk/">&copy; 2021-present Ravenware Ltd. All Rights Reserved.</a>
                                </div>
                            </div><!-- .nk-block -->
                        </div><!-- .nk-split-content -->
                        <div class="nk-split-content nk-split-stretch bg-abstract"></div><!-- .nk-split-content -->
                    </div><!-- .nk-split -->
                </div>
                <!-- wrap @e -->
            </div>
            <!-- content @e -->
        </div>
        <!-- main @e -->
    </div>
</template>

<script>
export default {
    data (){
        return {
            connecting: false,
            userName: '',
            password: '',
            os: '',
            browser: ''
        }
    },
    created () {
        this.storeLoginDetails()
        this.setUserInfo()
    }, 
methods: { // 
    setUserName (email) {
        this.userName  = email
    },
    login () {
        this.connecting = true
          let credentials = {userName: this.userName, password: this.password}
          this.$http.post('/auth/login', credentials)
             .then((response) => {
               if (response.data != null && response.data.settings != null && response.data.token != null) {
                 this.$store.commit('updateUserSettings', response.data.settings)
                 localStorage.trimToken = response.data.token
                 
                this.$notification['success']({
                   message: 'Logged In',
                   description: 'Welcome back to SoftSuite',
                 })
                 let settings  = response.data.settings
                this.$nextTick(() => {
                    this.$router.push({ name: settings.dashboard })
                });
                 this.connecting = false
               } else {
                this.connecting = false
                 this.$notification['warning']({
                   message: response.statusText,
                   description: response.message,
                 })
               }
             })
             .catch(error => {
                this.connecting = false
               this.$notification['warning']({
                 message: 'There was an error connecting. Please check your password and try again.' || 'Error',
                 description: error.message || 'Unable to connect to the database please contact support',
               })
             })
    },
    setUserInfo (){
        this.os = this.getOSInfo()
        this.browser = this.getBrowserInfo()
    },
    getBrowserInfo(){
            var test = function(regexp) {return regexp.test(window.navigator.userAgent)}
            switch (true) {
                case test(/edg/i): return "Microsoft Edge";
                case test(/trident/i): return "Microsoft Internet Explorer";
                case test(/firefox|fxios/i): return "Mozilla Firefox";
                case test(/opr\//i): return "Opera";
                case test(/ucbrowser/i): return "UC Browser";
                case test(/samsungbrowser/i): return "Samsung Browser";
                case test(/chrome|chromium|crios/i): return "Google Chrome";
                case test(/safari/i): return "Apple Safari";
                default: return "Other";
            }
    },
    getOSInfo() {
        var userAgent = window.navigator.userAgent,
        platform = window.navigator.platform,
        macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
        windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
        iosPlatforms = ['iPhone', 'iPad', 'iPod'],
        os = null;

        if (macosPlatforms.indexOf(platform) !== -1) {
            os = 'Mac OS';
        } else if (iosPlatforms.indexOf(platform) !== -1) {
            os = 'iOS';
        } else if (windowsPlatforms.indexOf(platform) !== -1) {
            os = 'Windows';
        } else if (/Android/.test(userAgent)) {
            os = 'Android';
        } else if (!os && /Linux/.test(platform)) {
            os = 'Linux';
        }

        return os;
    },
    storeLoginDetails() {
       //  this.$store.commit('updateUserSettings',{id:1, tenantId:11})
    },
}
}
</script>

<style>

</style>